<script setup lang="ts">
import type { HomePageHeader } from "@/types/marketing";
import UserActionModal from "@/components/marketing/UserActionModal.vue";
import HirableVideoModal from "@/components/marketing/home/HirableVideoModal.vue";
import TypeText from "@/components/marketing/TypeText.vue";
import AnimatedStats from "@/components/marketing/home/AnimatedStats.vue";
import LeadingBrands from "@/components/marketing/home/LeadingBrands.vue";
import HeaderImage from "@/components/marketing/home/HeaderImage.vue";

interface Props {
  content: HomePageHeader;
}

defineProps<Props>();

const userActionModalActive = ref(false);
const showVideoModal = ref(false);
</script>

<template>
  <section class="relative overflow-hidden">
    <div class="relative isolate w-full py-24 lg:py-40 overflow-visible">
      <div
        class="w-full max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 lg:grid lg:grid-cols-12 lg:gap-x-8 xl:gap-x-14 lg:items-center"
      >
        <div class="col-span-6 z-10 relative space-y-14">
          <div class="space-y-8">
            <div class="inline-flex items-center px-4 py-2 rounded-full bg-white shadow-md">
              <span
                class="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary text-sm font-semibold"
              >
                {{ content.subtitle }}
              </span>
            </div>

            <h1 class="text-dark-gray text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-bold tracking-tight">
              <TypeText
                :text="content.title"
                :key="content.title"
                size="large"
                :typeSpeed="40"
                :startDelay="200"
                :backSpeed="0"
                :backDelay="1000"
                :loop="false"
              />
            </h1>

            <p class="text-lg sm:text-xl leading-relaxed text-medium-gray/95 max-w-xl font-light">
              {{ content.description }}
            </p>
          </div>

          <div class="flex flex-col sm:flex-row gap-6 z-20">
            <PrimaryButton
              @click="userActionModalActive = true"
              large
              class="group w-full sm:w-auto px-7 py-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 motion-safe:hover:scale-105"
            >
              <span class="flex items-center text-sm">
                {{ content.cta.primary.text }}
                <Icon
                  :name="content.cta.primary.icon"
                  class="ml-1.5 group-hover:translate-x-1 transition-transform duration-300"
                />
              </span>
            </PrimaryButton>

            <OutlineButton
              large
              @click="showVideoModal = true"
              class="group w-full sm:w-auto px-7 py-4 rounded-full border-primary/30 hover:border-primary/70 motion-safe:hover:scale-105"
            >
              <span class="flex items-center text-sm">
                {{ content.cta.secondary.text }}
                <Icon
                  :name="content.cta.secondary.icon"
                  class="ml-1.5 group-hover:translate-x-1 transition-transform duration-300"
                />
              </span>
            </OutlineButton>
          </div>

          <AnimatedStats :stats="content.stats" />
          <LeadingBrands />
        </div>

        <HeaderImage :images="content.images" />
      </div>

      <div class="absolute bottom-0 left-0 right-0 h-px bg-primary/15"></div>

      <UserActionModal :show="userActionModalActive" @close="userActionModalActive = false" />
      <HirableVideoModal :show="showVideoModal" @close="showVideoModal = false" />
    </div>
  </section>
</template>
