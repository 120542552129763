<script setup lang="ts">
interface Props {
  show: boolean;
  maxWidth?: string;
  closeable?: boolean;
}

interface Emits {
  (e: "close"): void;
}

withDefaults(defineProps<Props>(), {
  maxWidth: "max-w-screen",
  closeable: true,
});

const emit = defineEmits<Emits>();

const close = () => {
  emit("close");
};
</script>

<template>
  <Modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close">
    <div class="relative z-10 h-[90vh] bg-black rounded-md shadow-2xl overflow-hidden">
      <button
        @click="close"
        class="absolute top-4 right-4 bg-white rounded-full h-12 w-12 flex items-center justify-center shadow-lg z-30 hover:bg-gray-100"
      >
        <Icon name="fa6-solid:xmark" class="w-7 h-7 text-gray-800" />
      </button>

      <div class="w-full h-full bg-black flex items-center justify-center">
        <iframe
          v-if="show"
          class="w-full h-full"
          src="https://www.youtube.com/embed/QJ_1YA8BHCo?autoplay=1&rel=0&modestbranding=1"
          title="Hirable Demo Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </Modal>
</template>
