<script setup lang="ts">
import { USER_TYPE } from "@/constants";
import UserOptions from "./UserOptions.vue";

interface Props {
  show: boolean;
  employerRoute?: string;
  candidateRoute?: string;
}

interface Emits {
  (e: "close"): void;
}

const props = withDefaults(defineProps<Props>(), {
  employerRoute: ROUTES.employers,
  candidateRoute: ROUTES.candidates,
});

const emit = defineEmits<Emits>();

const handleUser = (type: USER_TYPE) => {
  switch (type) {
    case USER_TYPE.employer:
      return navigateTo(props.employerRoute);
    case USER_TYPE.candidate:
      return navigateTo(props.candidateRoute);
  }
};
</script>

<template>
  <Modal :show="show" @close="$emit('close')" max-width="max-w-full sm:max-w-3xl">
    <div class="p-6 sm:p-10">
      <h2 class="text-2xl font-bold text-dark-gray mb-6 text-center">Choose Your Path</h2>
      <UserOptions @selected="(userType) => handleUser(userType)" />
    </div>
  </Modal>
</template>
