<script setup lang="ts">
import { useIntersectionObserver } from "@vueuse/core";
import { useReducedMotion } from "@/composables/use-reduced-motion";
import type { HomePageAnimatedStats } from "@/types/marketing";

interface Props {
  stats: HomePageAnimatedStats;
}

const props = defineProps<Props>();
const { prefersReducedMotion } = useReducedMotion();

const statsRef = ref<HTMLElement | null>(null);
const hasAnimated = ref(false);

type StatKey = keyof Props["stats"];
const animatedStats = reactive<Record<StatKey, { value: number }>>({
  costSaving: { value: 0 },
  timeSaving: { value: 0 },
  retention: { value: 0 },
});

const animateStats = () => {
  if (hasAnimated.value || prefersReducedMotion.value) {
    (Object.keys(animatedStats) as StatKey[]).forEach((key) => {
      animatedStats[key].value = props.stats[key].value;
    });
    return;
  }

  const duration = 2000; // 2 seconds
  let startTime: number | null = null;

  const easeOutExpo = (t: number): number => {
    return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
  };

  const animate = (currentTime: number) => {
    if (!startTime) startTime = currentTime;
    const elapsed = currentTime - startTime;
    const progress = Math.min(1, elapsed / duration);
    const easedProgress = easeOutExpo(progress);

    (Object.keys(animatedStats) as StatKey[]).forEach((key) => {
      animatedStats[key].value = Math.min(props.stats[key].value, props.stats[key].value * easedProgress);
    });

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      (Object.keys(animatedStats) as StatKey[]).forEach((key) => {
        animatedStats[key].value = props.stats[key].value;
      });
      hasAnimated.value = true;
    }
  };

  requestAnimationFrame(animate);
};

const { stop } = useIntersectionObserver(
  statsRef,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasAnimated.value) {
      animateStats();
      stop();
    }
  },
  { threshold: 0.3 }
);

onMounted(() => {
  if (prefersReducedMotion.value) {
    (Object.keys(animatedStats) as StatKey[]).forEach((key) => {
      animatedStats[key].value = props.stats[key].value;
    });
  }
});
</script>

<template>
  <div ref="statsRef" class="grid grid-cols-3 gap-8">
    <div v-for="(stat, key) in stats" :key="key" class="group relative flex flex-col transition-all duration-300">
      <div class="relative mb-1 flex items-baseline gap-1 overflow-hidden">
        <span class="text-4xl font-bold text-primary tabular-nums">
          {{ stat.prefix }}{{ animatedStats[key as StatKey].value.toFixed(2) }}{{ stat.suffix }}
        </span>
        <div
          class="absolute bottom-0 left-0 h-[2px] w-0 bg-primary transition-all duration-700 group-hover:w-full"
          aria-hidden="true"
        />
      </div>
      <span class="text-medium-gray/95 font-medium text-sm">
        {{ stat.label }}
      </span>
      <div
        class="absolute -inset-4 -z-10 scale-0 rounded-2xl bg-primary/5 opacity-0 blur-xl transition-all duration-300 group-hover:scale-100 group-hover:opacity-70"
        aria-hidden="true"
      />
    </div>
  </div>
</template>
