<script setup lang="ts">
import { useReducedMotion } from '@/composables/use-reduced-motion';

const { prefersReducedMotion } = useReducedMotion();
</script>

<template>
  <div class="flex items-center gap-3 opacity-95 stagger-item" :class="{ 'animate-fade-in-up': !prefersReducedMotion }">
    <span class="text-sm text-medium-gray/95 font-medium">Trusted by leading brands</span>
    <div class="h-px bg-gradient-to-r from-gray-300 to-transparent flex-grow"></div>
  </div>
</template>
