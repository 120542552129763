<script setup lang="ts">
import { useReducedMotion } from '@/composables/use-reduced-motion';

interface Props {
  icon: string;
  title: string;
  description: string;
  iconBgColor?: string;
  iconTextColor?: string;
  delay?: number;
}

const props = defineProps<Props>();
const { prefersReducedMotion } = useReducedMotion();

const style = computed(() => ({
  animationDelay: props.delay ? `${props.delay}ms` : '0ms'
}))
</script>

<template>
  <div 
    class="transform transition-all duration-300 hover:scale-105 fade-up"
    :style="style"
  >
    <div class="bg-white rounded-xl p-4 shadow-md border border-light-gray/10">
      <div class="flex items-center mb-2">
        <div 
          class="w-8 h-8 rounded-full flex items-center justify-center mr-3"
          :class="[props.iconBgColor || 'bg-primary/10', props.iconTextColor || 'text-primary']"
        >
          <Icon :name="props.icon" class="h-4 w-4" />
        </div>
        <p class="text-sm font-semibold text-dark-gray">{{ props.title }}</p>
      </div>
      <p class="text-xs text-medium-gray">{{ props.description }}</p>
    </div>
  </div>
</template> 