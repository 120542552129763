<script setup lang="ts">
import { useIntersectionAnimation } from '@/composables/use-intersection-animation';
import type { HomePageSocialProof } from "@/types/marketing";

interface Props {
  content: HomePageSocialProof;
}

defineProps<Props>();
const { elementRef } = useIntersectionAnimation();
</script>

<template>
  <section class="relative">
    <div ref="elementRef" class="max-w-7xl mx-auto fade-up">
      <div class="rounded-2xl shadow-xl p-8 relative">
        <div class="absolute inset-0 opacity-5">
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M 0 10 L 40 10 M 10 0 L 10 40" stroke="currentColor" stroke-width="0.5" fill="none" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        </div>

        <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between relative z-10">
          <div class="flex flex-col sm:flex-row items-start sm:items-center gap-y-4 sm:gap-y-0 mb-6 sm:mb-0 fade-up" :style="{ animationDelay: '0.2s' }">
            <div class="avatar-container flex items-center">
              <div v-for="(avatar, index) in content.avatars" :key="`avatar-${index}`">
                <BaseImage
                  :src="avatar.src"
                  :alt="avatar.alt"
                  class="rounded-full h-12 w-12 object-cover ring-4 ring-white shadow-md -ml-2 first:ml-0"
                  :style="{
                    zIndex: content.avatars.length - index,
                    aspectRatio: '1/1'
                  }"
                  loading="lazy"
                  width="48"
                  height="48"
                  sizes="48px"
                />
              </div>
            </div>

            <div class="sm:ml-4">
              <p class="font-medium text-dark-gray">
                <span class="text-lg font-bold">{{ content.stats.candidates.count }}</span> {{ content.stats.candidates.text }}
              </p>
            </div>
          </div>

          <div class="flex flex-col items-center sm:items-end">
            <div class="flex items-center mb-1">
              <div v-for="i in content.stats.rating.stars" :key="`star-${i}`" class="relative">
                <Icon
                  :name="content.stats.rating.icon"
                  class="h-6 w-6 text-warning pop-in"
                  :style="{ animationDelay: `${i * 0.1 + 0.5}s` }"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 pt-6 border-t border-light-gray/30 fade-up" :style="{ '--animation-delay': '900ms' }">
          <div class="flex items-start lg:items-center">
            <Icon
              :name="content.testimonial.quoteIcon"
              class="h-24 w-24 sm:h-8 sm:w-8 text-primary-light/50 mr-2 mt-1 transform rotate-180 flex"
            />
            <p class="italic text-medium-gray">
              {{ content.testimonial.quote }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
