<script setup lang="ts">
import CallToAction from "@/components/marketing/CallToAction.vue";
import Header from "@/components/marketing/home/Header.vue";
import HowItWorks from "@/components/marketing/home/HowItWorks.vue";
import SocialProof from "@/components/marketing/home/SocialProof.vue";

const data = await useSeoAndGetContent(ROUTES.home);
const { howItWorks, header, socialProof, callToAction } = data;

definePageMeta({
  layout: "marketing",
});
</script>

<template>
  <div class="relative">
    <div class="flex flex-col space-y-16 sm:space-y-24">
      <Header :content="header" />
      <HowItWorks :content="howItWorks" />
      <SocialProof :content="socialProof" />
      <CallToAction :content="callToAction" />
    </div>
  </div>
</template>

