<script setup lang="ts">
import { useReducedMotion } from '@/composables/use-reduced-motion';

interface Props {
  images: {
    dashboard: string;
    avatars: Array<{
      src: string;
      alt: string;
      class?: string;
    }>;
  }
}

defineProps<Props>();
const { prefersReducedMotion } = useReducedMotion();

const imageContainerRef = ref<HTMLElement | null>(null);
const mousePosition = ref({ x: 0, y: 0 });
const isHovering = ref(false);

const transform = computed(() => {
  if (!isHovering.value) return { rotateX: 0, rotateY: 0, translateZ: 0 };

  const x = mousePosition.value.x;
  const y = mousePosition.value.y;

  return {
    rotateX: (y - 0.5) * -8,
    rotateY: (x - 0.5) * 8,
    translateZ: 50,
  };
});

const handleMouseMove = (event: MouseEvent) => {
  if (prefersReducedMotion.value || !imageContainerRef.value) return;

  const { left, top, width, height } = imageContainerRef.value.getBoundingClientRect();
  mousePosition.value = {
    x: (event.clientX - left) / width,
    y: (event.clientY - top) / height,
  };
};

const handleMouseLeave = () => {
  isHovering.value = false;
  mousePosition.value = { x: 0, y: 0 };
};

const handleMouseEnter = () => {
  isHovering.value = true;
};
</script>

<template>
  <div
    class="col-span-6 mt-14 lg:mt-0 relative z-10 [perspective:1000px] [transform-style:preserve-3d] transition-transform duration-500 ease-out"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
    @mouseenter="handleMouseEnter"
    :style="
      !prefersReducedMotion && isHovering
        ? `transform: perspective(1000px) rotateX(${transform.rotateX}deg) rotateY(${transform.rotateY}deg) translateZ(${transform.translateZ}px)`
        : ''
    "
  >
    <div class="relative z-20 hidden lg:block">
      <div class="relative rounded-2xl overflow-hidden">
        <div class="relative w-full" style="aspect-ratio: 16/9;">
          <BaseImage
            :src="images.dashboard"
            alt="Hirable Dashboard"
            class="w-full h-full absolute top-0 left-0 rounded-2xl border border-gray-200 shadow-lg"
            sizes="sm:100vw lg:75vw xl:1200px"
            width="1200"
            height="675"
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-4 mt-8 lg:absolute lg:-bottom-12 lg:left-0 lg:z-30 lg:w-48 xl:w-56">
      <div 
        v-for="(image, index) in images.avatars"
        :key="index"
        :class="['col-span-1', image.class]"
      >
        <BaseImage
          :src="image.src"
          :alt="image.alt"
          class="aspect-[3/4] w-full rounded-xl object-cover shadow-md border border-gray-200"
          sizes="(max-width: 768px) 33vw, (max-width: 1280px) 48px, 56px"
        />
      </div>
    </div>
    <div class="absolute top-10 right-6 xl:right-10 z-20 hidden lg:block">
      <div class="bg-white rounded-xl p-4 shadow-md">
        <div class="flex items-center">
          <Icon name="material-symbols:verified" class="text-secondary h-6 w-6 mr-2" />
          <span class="text-dark-gray font-medium">Verified profiles</span>
        </div>
      </div>
    </div>
  </div>
</template>
