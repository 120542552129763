<script setup lang="ts">
import HirableVideo from "./HirableVideo.vue";
import FeatureCard from "./FeatureCard.vue";
import { useReducedMotion } from "@/composables/use-reduced-motion";
import { useIntersectionAnimation } from "@/composables/use-intersection-animation";
import type { HomePageHIW } from "@/types/marketing";

interface Props {
  content: HomePageHIW;
}

defineProps<Props>();
const { prefersReducedMotion } = useReducedMotion();
const { elementRef } = useIntersectionAnimation();
</script>

<template>
  <section class="relative overflow-hidden">
    <div ref="elementRef" class="relative w-full py-32 lg:py-40 overflow-x-hidden">
      <div class="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-20 flex flex-col items-center fade-up">
          <div class="flex items-center px-4 py-2 rounded-full bg-white shadow-md mb-4">
            <span
              class="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary text-sm font-semibold "
            >
              {{ content.subtitle }}
            </span>
          </div>

          <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold tracking-tight text-dark-gray mb-4 relative">
            {{ content.title }}
            <span
              class="absolute -bottom-2 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-primary-light to-transparent"
            ></span>
          </h2>

          <p class="mt-4 text-lg text-medium-gray max-w-3xl mx-auto">
            {{ content.description }}
          </p>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-3 md:gap-6 max-w-3xl mx-auto mb-12 px-4 fade-up">
          <FeatureCard
            v-for="(feature, index) in content.features"
            :key="feature.title"
            v-bind="feature"
            :delay="index * 200"
            :prefersReducedMotion="prefersReducedMotion"
          />
        </div>

        <HirableVideo class="fade-up" :style="{ animationDelay: '0.6s' }" :content="content.video" />
      </div>
    </div>
  </section>
</template>
